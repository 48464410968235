/* TopNav */
.top-nav {
    display: flex;
    position: relative;
    justify-content: center;
    padding: 10px 0;
    width: 100%;
}

.top-items {
    display: flex;
    position: relative;
    width: 1000px;
    justify-content: space-between;
}

.top-nav img {
    position: relative;
    left: 0;
    width: 150px;
    height: 60px;
    cursor: pointer;
}

.right-nav {
    position: relative;
    right: 0;
    display: flex;
    align-items: center;
    gap: 80px;
}

.right-nav > div {
    cursor: pointer;
    transition: color 0.3s ease;
    white-space: nowrap;
}

.right-nav > div:hover {
    color: #1d67cd;
}

.right-nav > div.active {
    color: #1d9ae4;
}

.right-nav > div:active {
    animation: clickAnimation 0.3s;
}

@keyframes clickAnimation {
    0% {
        color: #1d9ae4;
    }

    50% {
        color: #66c2ff;
    }

    100% {
        color: #1d9ae4;
    }
}

@media (max-width: 430px) {
    .top-items {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .right-nav {
        display: none;
    }

    .nav-bar {
        display: flex;
        flex-direction: column;
        gap: 8px;
        position: absolute;
        right: 20px;
        z-index: 1100;
    }

    .nav-bar > div {
        width: 25px;
        height: 2px;
        background-color: black;
        transition: transform 0.3s, opacity 0.3s;
    }

    .nav-bar.open > div:nth-child(1) {
        transform: rotate(45deg) translate(7px, 7px);
    }

    .nav-bar.open > div:nth-child(2) {
        opacity: 0;
    }

    .nav-bar.open > div:nth-child(3) {
        transform: rotate(-45deg) translate(7px, -7px);
    }

    .menu-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: white;
        z-index: 1000;
    }

    .menu-content > div {
        margin: 20px 0;
        font-size: 24px;
        cursor: pointer;
        color: black;
        transition: color 0.3s ease;
    }

    .menu-content > div:hover {
        color: #1d67cd;
    }

    .menu-content > div.active {
        color: #1d9ae4;
    }
}

.language-select {
    right: -15%;
    position: absolute;
    display: inline-block;
    top: 8px;
}

.language-select button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 5px;
}

.language-select img {
    width: 40px;
    height: 30px;
    border-radius: 10%;
    border: 1px solid #e8e6e6;
}

.language-dropdown {
    position: absolute;
    background-color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 10px;
    list-style: none;
    margin: 0;
    z-index: 1000;
    right: 5px;
    width: 130px;
}

.language-dropdown li {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    cursor: pointer;
}

.language-dropdown li:hover {
    background-color: #f1f1f1;
}

.language-dropdown img {
    width: 30px;
    height: 20px;
    border-radius: 10%;
    margin-right: 8px;
}

/* 모바일 버전 스타일 */
@media (max-width: 430px) {
    .language-select {
        position: absolute;
        top: 10px;
        width: 60px;
        left: 0;
        margin-left: 0;
        margin-right: auto;
    }

    .language-dropdown {
        position: absolute;
        background-color: white;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        padding: 10px;
        list-style: none;
        margin: 0;
        z-index: 1000;
        left: 5px;
        width: 130px;
    }
}
/* home */

.home-main {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home-video {
    width: 100%;
    height: 600px;
    object-fit: cover;
}

.title-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    text-align: center;
    height: 360px;
}

.title-1 > h1 {
    font-weight: 500;
    font-size: 40px;
}

.mission-service {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
}

.our-mission {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 450px;
}

.our-mission > .left {
    display: flex;
    flex: 2;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    color: white;
    background-color: #ea464e;
    width: 50%;
}

.our-mission > .left > .items > .box {
    border-bottom: 1px solid white;
    width: 30px;
}

.our-mission > .left > p {
    font-size: 17px;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    transition: border-bottom 0.3s ease-in-out;
}

.our-mission > .left > p:hover {
    border-bottom: 2px solid white;
}

.our-mission > .right {
    flex: 3;
    color: white;
    background-color: #edebeb;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    white-space: normal;
    width: 50%;
}

.our-service {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    white-space: normal;
    height: 400px;
}

.example-pic {
    display: flex;
    justify-content: space-around;
    background-color: #e8e6e6;
    width: 100%;
}

.example-pic > .items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 50px 0 0 0;
    height: auto;
    gap: 40px;
}

.example-pic > .items > p:hover {
    border-bottom: 2px solid white;
}

.example-pic > .items > div > p {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 0 0 0 30px;
    width: 260px;
    height: 60px;
    position: relative;
    bottom: 40px;
    left: 20px;
    font-size: 17px;
}

.example-pic > .items > div > .image-container {
    width: 300px;
    height: 300px;
    overflow: hidden;
    position: relative;
}

.cropped-image {
    width: 100%;
    height: 100%;
    position: absolute;
    white-space: nowrap;
    object-fit: cover;
}

.zoomed {
    transform: scale(1.5);
    top: -80px;
    left: 70px;
}

.client {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 400px;
}

.client > .items > .content {
    display: flex;
    width: 700px;
    overflow: hidden;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.client > .items > .content > p {
    width: 700px;
    transition: transform 0.5s ease-in-out;
    flex-shrink: 0;
    margin: 40px 0 0 0;
    line-height: 1.5;
}

.client > .items > .page {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}

.client > .items > .page > p {
    width: 7px;
    height: 7px;
    background-color: #85aff1;
    border-radius: 10px;
}

.client > .items > .page > p:hover {
    cursor: pointer;
    background-color: #0d47a1;
}

.logo-scroller {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
}

.logo-track {
    display: inline-block;
    animation: scroll 200s linear infinite;
    padding-top: 40px;
}

.logo {
    width: 100px;
    display: inline-block;
    height: 100px;
    margin: 0 10px;
}

.cop-desc {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 80%;
    padding: 20px 0 30px 0;
}

.cop-desc > .cop-info {
    display: flex;
}

.cop-desc > .cop-info > p {
    line-height: 1.2;
    overflow: hidden;
    white-space: wrap;
}

.cop-desc > .cop-info > p > p {
    display: inline;
    border-left: 1px solid black;
    padding: 0 0 0 10px;
    margin: 0 0 0 10px;
}

.cop-desc > .cop-sns {
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.cop-desc > .cop-sns > a > .icon {
    color: black;
    width: 35px;
    height: 35px;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

@media (max-width: 430px) {
    .home-main {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .home-video {
        width: 100%;
        height: 250px;
    }

    .title-1 {
        height: 200px;
        width: 250px;
        white-space: normal;
    }

    .title-1 > h1 {
        font-size: 30px;
    }

    .our-mission {
        flex-direction: column;
        height: auto;
    }

    .our-mission > .left,
    .our-mission > .right {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
    }

    .our-mission > .right {
        color: black;
        background-color: white;
    }

    .our-service {
        flex-direction: column;
        align-items: center;
        padding: 20px;
        box-sizing: border-box;
        width: 100%;
    }

    .client {
        height: 400px;
    }

    .client > .items > .content {
        width: 300px;
    }

    .client > .items > .content > p {
        width: 300px;
        white-space: wrap;
        transform: translateX(-activeIndex * 400px);
    }
}

/* mission */
.mission-main {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mission-page {
    width: 100%;
    height: 400px;
    object-fit: cover;
    display: block;
}

.mission-cop-desc {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: wrap;
    text-align: center;
    height: 360px;
}

.mission-cop-desc > .items > h1 {
    font-size: 30px;
}

.features-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.features-container > .feature-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px;
    width: 100%;
    padding: 10px 0 30px 0;
}

.feature-item {
    display: flex;
    flex-direction: column;
    max-width: 360px;
    margin-bottom: 30px;
}

.feature-image {
    width: 100%;
    height: 210px;
    border-radius: 8px;
    object-fit: cover;
}

.feature-item h3 {
    font-size: 1.2rem;
    font-weight: normal;
    margin: 10px 0;
}

.feature-item h2 {
    font-size: 1.5rem;
    margin: 10px 0;
}

.feature-item p {
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
}

.quote-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-image: url('./assets//files//back3.png');
    color: white;
    width: 100%;
    padding: 30px 0;
}

.quote-section blockquote {
    width: 100%;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1.2;
}

.quote-section .author {
    display: block;
    margin: 10px 0 0 0;
    font-size: 1rem;
    font-weight: normal;
}

.quote-section p {
    font-size: 15px;
    line-height: 1.7;
    text-align: left;
    padding: 20px 0 0 0;
    max-width: 1200px;
    line-height: 2.3;
}

.quote-section strong {
    font-weight: bold;
}

.inclusion-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #edebeb;
    padding: 50px 0;
}

.inclusion-section > .items {
    width: 95%;
}

.inclusion-section > .items > .img {
    height: 500px;
    width: 100%;
    object-fit: cover;
    display: block;
}

.inclusion-section > .items > .content-container {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    background-color: white;
    padding: 20px 0;
}

.inclusion-section > .items > .content-container > .main-text {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 50%;
    font-weight: 800;
    font-size: 35px;
    white-space: wrap;
    overflow: hidden;
}

.inclusion-section > .items > .content-container > .author {
    display: flex;
    align-items: center;
    justify-content: space-around;
    line-height: 2;
    width: 50%;
    white-space: wrap;
}

@media (max-width: 430px) {
    .mission-page {
        height: 200px;
    }

    .mission-cop-desc {
        width: 90%;
        height: 500px;
    }

    .mission-cop-desc > .items > h1 {
        font-size: 25px;
    }

    .quote-section {
        width: 100%;
    }

    .inclusion-section > .items > .img {
        height: 200px;
    }

    .inclusion-section > .items {
        width: 100%;
    }

    .inclusion-section > .items > .content-container {
        display: flex;
        flex-direction: column;
    }

    .inclusion-section > .items > .content-container > .main-text {
        width: 100%;
        font-size: 25px;
    }

    .inclusion-section > .items > .content-container > .author {
        width: 90%;
        white-space: wrap;
    }
}

/* lab */

.lab-main {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main-img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    display: block;
}

.title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    background-color: #edebeb;
    width: 100%;
}

/* lab */

.lab-main {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main-img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    display: block;
}

.title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    background-color: #edebeb;
    width: 100%;
}

.lab-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: #edebeb;
    padding: 20px 0;
}

.lab-content {
    width: 90%;
    max-width: 1000px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.lab-item {
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    width: 400px;
    display: flex;
    flex-direction: column;
}

.lab-item img {
    width: 100%;
    height: 300px;
    object-fit: cover;
}

.lab-item-img.fill {
    object-fit: fill;
}

.lab-text {
    padding: 20px;
}

.lab-text h3 {
    margin: 0 0 10px;
    font-size: 1.25rem;
}

.lab-text p {
    margin: 0 0 10px;
    font-size: 1rem;
    color: #333;
}

.lab-text em {
    font-size: 0.875rem;
    color: #777;
}

/* 반응형 디자인 */
@media (max-width: 768px) {
    .main-img {
        height: 300px;
    }
}
